<template>
  <div :id="`chart_${chartId}`" class="chart" :class="chartData.type">
    <canvas :id="`canvas_${chartId}`"></canvas>
  </div>
</template>

<script>
  import Chart from "chart.js/auto";
  import { chartLabelFormatter } from "@/helpers/chartHandlers.js";

  export default {
    data() {
      return {
        chartId: null,
        chartData: null,
        chart: null,
        ctx: null,
        options: {
          responsive: true,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: this.maintainAspectRatio,
          scales: this.type === "bar" && {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
            y: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
          animation: {
            onComplete: () => {
              this.animationIsLoading = false;
              this.setAnimationIsLoading();
            },
          },
          plugins: {
            legend: {
              display: this.legend.show,
              position: this.legend.position,
              labels: {
                boxWidth: 20,
                padding: 5,
                font: {
                  size: 11,
                },
              },
            },
            datalabels: {
              display: this.showLabels,
              formatter: (value) => {
                if (this.formatMoneyForLabel) {
                  return this.formatMoney(value);
                } else {
                  return value;
                }
              },
              color: "white",
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) =>
                  chartLabelFormatter(
                    tooltipItem,
                    this.formatMoney,
                    this.formatMoneyForLabel
                  ),
              },
            },
          },
        },
        animationIsLoading: false,
      };
    },

    props: {
      data: {
        type: Object,
      },
      type: {
        type: String,
        default: "bar",
      },
      legend: {
        type: Object,
      },
      aspectRatio: {
        type: Number,
      },
      maintainAspectRatio: {
        type: Boolean,
        default: false,
      },
      formatMoneyForLabel: {
        type: Boolean,
        default: true,
      },
      showLabels: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      data() {
        if (!this.chart || this.animationIsLoading) return;
        this.chart.destroy();
        this.createChartData();
        this.createChart();
      },
    },

    methods: {
      createChart() {
        this.animationIsLoading = true;
        this.setAnimationIsLoading();
        this.chart = new Chart(this.ctx, this.chartData);
      },
      createChartData() {
        this.chartData = {
          type: this.type,
          data: this.data,
          options: this.options,
        };
      },
      setAnimationIsLoading() {
        this.$emit("setLoading", this.animationIsLoading);
      },
    },

    created() {
      if (this.id == null) {
        this.chartId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      } else {
        this.chartId = this.id;
      }
      this.createChartData();
    },

    mounted() {
      this.ctx = document.getElementById(`canvas_${this.chartId}`);
      this.createChart();
    },
  };
</script>
