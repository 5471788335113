<template>
  <div class="main-content">
    <h3 class="page-heading">Refunds & Cancellations</h3>
    <div class="cards-container" :class="layout">
      <RefundSummary />
      <!-- 
      <DoughnutChartCard
        chartId="110"
        title="Refunds"
        :size="
          layout === 'xl-desktop' ? null : layout === 'desktop' ? 'lg' : 'full'
        "
        :data="doughnutData"
        :layout="layout"
        :highlightedData="uninvoicedHighlightedDataArr" />
      <DoughnutChartCard
        chartId="120"
        title="Cancellations"
        :size="
          layout === 'xl-desktop' ? null : layout === 'desktop' ? 'lg' : 'full'
        "
        :data="doughnutData"
        :layout="layout"
        :highlightedData="uninvoicedHighlightedDataArr" />
      <Card size="full" chartOrGrid="grid-layout" flexDirection="column">
        <div class="card__title full-width">
          <div class="card__title-options row">
            <h3 class="active">{{ currentList }} List</h3>
            <h3 @click="changeListData">{{ listOption }} List</h3>
          </div>
          <button class="config"><More /></button>
        </div>
        <div class="table-controllers" v-if="currentList === 'refund'">
          <button><Download />Download</button>
          <button><Check />Approve</button>
          <button><Cancel />Decline</button>
        </div>
        <div class="table-controllers" v-else>
          <button><Download />Download</button>
        </div>
        <AgGridVue
          style="width: 100%; min-height: 700px"
          class="ag-theme-alpine"
          :columnDefs="gridData?.columnDefs"
          :rowData="gridData?.rowData"
          :rowSelection="rowSelection">
        </AgGridVue>
      </Card> -->
    </div>
  </div>
</template>

<script>
  import { screenDetector } from "@/helpers/screenDetector";
  import RefundSummary from "@/components/Sales_/RefundSummary";

  export default {
    data() {
      return {
        showChart: true,
        gridData: null,
        layout: "desktop",
        loading: false,
        rowSelection: null,
        currentList: "refund",
      };
    },
    components: {
      RefundSummary,
    },

    computed: {
      listOption() {
        if (this.currentList === "refund") {
          return "cancellation";
        }
        return "refund";
      },
    },

    methods: {
      updateIfDesktopLayoutState() {
        this.layout = screenDetector();
      },

      changeListData() {
        if (this.currentList === "refund") {
          this.currentList = "cancellation";
          this.gridData = this.uninvoicedGridData;
        } else {
          this.currentList = "refund";
          this.gridData = this.transactionGridData;
        }
      },
    },

    created() {
      this.rowSelection = "multiple";
    },

    mounted() {
      this.updateIfDesktopLayoutState();
      window.addEventListener("resize", this.updateIfDesktopLayoutState);
      this.gridData = this.transactionGridData;
    },
    unmounted() {
      window.removeEventListener("resize", this.updateIfDesktopLayoutState);
    },
  };
</script>
