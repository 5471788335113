<template>
  <div class="grid-view">
    <Chart
      v-if="chartOrGrid === 'chart'"
      :data="chartData"
      :type="chartType"
      :legend="{ show: true, position: 'right' }" />

    <AgGridVue
      style="width: 100%; min-height: 700px"
      class="ag-theme-alpine"
      :class="{ 'no-data': !rowData, 'hide-grid': chartOrGrid === 'chart' }"
      @gridReady="onGridReady"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="'single'"
      :pagination="true"
      :paginationAutoPageSize="true">
    </AgGridVue>
    <p class="error grid-error-overlay" v-if="errorTextFromAPI">
      {{ errorTextFromAPI }}
    </p>
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue3";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";
  import Chart from "@/components/charts/Chart.vue";

  export default {
    components: {
      AgGridVue,
      Chart,
    },
    props: {
      chartOrGrid: {
        type: String,
        default: "grid",
      },
      dataset: {
        type: String,
        default: "items",
      },
      chartType: {
        type: String,
        default: "doughnut",
      },
      dateFrom: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal() + 7)
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
      dateTo: {
        type: String,
        default: LocalDate.now()
          .minusDays(LocalDate.now().dayOfWeek().ordinal())
          .format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
      },
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        errorTextFromAPI: null,
        gridApi: null,
        gridColumnApi: null,
        rowData: null,
        chartData: null,
      };
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: "Order Id",
            field: "orderId",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
          },
          {
            headerName: "Value",
            field: "orderValue",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
            valueFormatter: (params) => {
              return this.formatMoney(params.value);
            },
          },
          {
            headerName: "Status",
            field: "refundStatus",
            resizable: true,
            sortable: true,
            filter: true,
            width: 140,
          },
          {
            headerName: "Placed",
            field: "orderDateTimePlaced",
            resizable: true,
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
              return this.formatDateTime(params.value);
            },
          },
          {
            headerName: "Requested",
            field: "requestedOn",
            resizable: true,
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
              return this.formatDateTime(params.value);
            },
          },
          {
            headerName: "Channel",
            field: "orderChannel",
            resizable: true,
            sortable: true,
            filter: true,
            width: 120,
          },
        ];
      },

      ...mapGetters({}),
    },
    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.getSummary();
      },

      getSummary() {
        this.gridApi.showLoadingOverlay();

        this.errorTextFromAPI = null;

        this.getSummaryEndpoint()
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject("Could not download summary.");
          })
          .then((data) => {
            // set items/channels/alterations based on dataset prop:
            this.rowData = data;
            this.gridApi.hideOverlay();

            //setup chart data
            // this.chartData = {
            //   labels: data[this.dataset].map(
            //     (x) => x[this.nameFields.nameField]
            //   ),
            //   datasets: [
            //     {
            //       data: data[this.dataset].map((x) => x.amount),
            //       backgroundColor: ["#e59192", "#375cd8", "#498e7b"],
            //       hoverOffset: 4,
            //     },
            //   ],
            // };
          })
          .catch((err) => {
            window.log.error(err);
            this.gridApi.hideOverlay();
            this.rowData = null;
            this.errorTextFromAPI = err;
          });
      },

      getSummaryEndpoint() {
        switch (this.operatorType) {
          case "vendor":
            return store.state.apiPrivate.client.endpoints.vendorRefunds.get(
              this.operatorId
            );
          case "venue":
            return store.state.apiPrivate.client.endpoints.venueRefunds.get(
              this.operatorId
            );
          default:
            window.log.error(
              "[❌] Failed to resolve a refund endpoint to use for your operation type."
            );
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .hide-grid {
    display: none;
  }
</style>
